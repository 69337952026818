<template>
  <section class="view-main">
    <b-container>
      <b-row class="justify-content-center">
        <b-col lg="6" md="10" sm="12" cols="12">
          <b-card tag="article" class="border-0">
            <template #header>
              <h4 class="mb-0">实名认证</h4>
            </template>

            <b-form ref="form" @submit="onSubmit" v-if="show">
              <b-form-group
                id="input-group-1"
                label="姓  名"
                label-for="input-1"
                invalid-feedback="请输入姓名"
              >
                <b-form-input
                  id="input-1"
                  v-model="form.card_name"
                  type="tel"
                  required
                  placeholder=""
                  trim
                ></b-form-input>
              </b-form-group>
              <b-form-group
                id="input-group-2"
                label="身份证号"
                label-for="input-2"
                invalid-feedback="请输入身份证"
              >
                <b-form-input
                  id="input-2"
                  v-model="form.card_num"
                  type="text"
                  required
                  placeholder=""
                  trim
                  maxlength="18"
                ></b-form-input>
              </b-form-group>
              <b-row>
                <b-col style="text-align: center">
                  <b-button type="submit" variant="primary">保 存</b-button>
                  <!-- <b-button type="reset" variant="danger">Reset</b-button> -->
                </b-col>
              </b-row>
            </b-form>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>

export default {
  name: "Certification",
  components: {},
  data() {
    return {
      form: {
        card_name: "",
        card_num: "",
      },
      show: true,
    };
  },
  computed: {},
  created() {},
  mounted() {
    this.request_user_card_info();
  },
  methods: {
    onSubmit(evt) {
      // if (this.form.card_num.replace(/\s+/g, "").length < 18) {
      //   this.$message.show("请输入正确的身份证号",'danger',1500);
      //   return false;
      // }
      this.$API.submitUserAuth(this.form)
        .then((response) => {
          // console.log(response);
          let res = response.data;
          if (res.status == 1) {
            this.$message.show("信息已修改!", "success");
          } else {
            this.$message.show("操作失败!", "danger");
          }
        })
        .catch((error) => {
          Promise.reject(error);
        });
    },

    request_user_card_info() {
      this.$lcgLoading.show("loading...", "success");
      this.$API.getUserInfo({})
        .then((response) => {
          this.$lcgLoading.hide();
          // console.log(response);
          let res = response.data;
          if (res.status == 1) {
            this.form.card_name = res.data.card_name;
            this.form.card_num = res.data.card_num;
          } else {
            this.$message.show("读取用户信息失败!", "danger");
          }
        })
        .catch((error) => {
          Promise.reject(error);
        });
    },
  },
};
</script>
